<template>
  <div class="bigbox">
    <div>
          <van-nav-bar title="金额详情" left-arrow @click-left="$router.back()"></van-nav-bar>
    </div>
    <div class="main">
       <div class="topbox">
      <div>
        <div>发票总额</div>
        <div> <span style="color:red">77</span> 元</div>
      </div>
    </div>
    <div style="padding-left:20px;height:30px;line-height: 30px;margin-top:10px;background:#eee">发票总数{{}}张</div>
    <div class="button">
       <div class="flex">
         <div>发票内容</div>
         <div>*运输服务*客运服务费</div>
       </div>
       <van-divider />
       <div class="flex">
         <div>开票方</div>
         <div>成都米花棠科技有限公司</div>
       </div>
       <van-divider />
       <div class="flex">
         <div>发票总额</div>
         <div>73.98元</div>
       </div>
    </div>
    <button class="btn">提交</button>
    </div>
   
  </div>
</template>

<script>
import { getbill_api } from '../http/api/service'
export default {
  name: 'TaxiH5webDetails',

  data() {
    return {
      
    };
  },

  mounted() {
    this.get()
  },

  methods: {
   async get(){
      let res=await getbill_api({

      })
      console.log(res);
    }
  },
};
</script>

<style lang="scss" scoped>
.bigbox{
  background: #eee;
  width: 100%;
  height: 100vh;
   display: flex;
  flex-direction: column;
  .main{
    margin-top: 10px;
    flex: 1;        
     overflow-y: scroll;
    .topbox{ 
    background: #fff;
    height: 80px;
    text-align:center;
    line-height: 40px;
    
  }
  .button{
       background: #fff;  
    .flex{  
      height: 40px;
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      align-items: center;
    }
  }
  .btn{
    background: #EB4541;
    height: 40px;
    width: 90%;
    margin: 0 5%;
    border: 0;
    margin-top: 20px;
    border-radius: 15px;
    color: #fff;
  }
  }
  
}
</style>